import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useLocalization } from "gatsby-theme-i18n";
import { Grid, Container, Stack, Box, Typography, Button } from "@mui/material";
import { navigate } from "gatsby";
import Header from "../../../components/Header";
import Mail from "../../../services/MailService";
import { theme } from "../../../themeV2";
import StepProgress from "../../../components/request-info/StepProgress";
import FormStep1 from "../../../components/request-info/FormStep1";
import FormStep2 from "../../../components/request-info/FormStep2";
import FormStep3 from "../../../components/request-info/FormStep3";
import FormStep4 from "../../../components/request-info/FormStep4";
import ReviewStep from "../../../components/request-info/ReviewStep";
import ThankRequestModal from "../../../components/ThankRequestModal";
import Seo from "../../../components/seo";

const metadata = {
  title: "Golden data hub",
  description: "Improve the interaction and automation in one entry point and unlock business opportunities accessing global market data from all major providers.",
};

function ResquestInfo() {
  const [activeStep, setActiveStep] = useState(1);
  const [sending, setSending] = useState(false);
  const [disabledNext, setDisabledNext] = useState(true);
  const disabledButton = (disabledNext || sending);
  const [thanksModalOpen, setThanksModalOpen] = useState(false);
  const [PersonalData, setPersonalData] = useState({
    businessType: "",
    company: "",
    country: "",
    email: "",
  });
  const [selectedProductUniverses, setSelectedProductUniverses] = useState({
    range: 0,
    dataQuality: [],
    markets: [],
  });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDataPoints, setSelectedDataPoints] = useState([]);
  const [error, setError] = useState(false);
  const { locale } = useLocalization();
  const urlLanding = `/${locale}/products/golden-data-hub`;

  const step3 = selectedDataPoints.map((tab) => {
    const tabName = tab.label;
    const items = tab.items.map((item) => item.label);
    return `${tabName}: ${items.join(", ")}`;
  }).join(" - ");

  const step4 = `Range: ${selectedProductUniverses.range} -
    Data Quality: ${selectedProductUniverses.dataQuality.map((item) => item.label).join(", ")} -
    Markets: ${selectedProductUniverses.markets.map((item) => item.label).join(", ")}`;

  const data = {
    subject: "Request info - Allfunds Corporate",
    type: "product",
    product: "golden-data-hub",
    hasTemplate: false,
    from: "noreply@allfunds.com",
    email: PersonalData.email,
    company: PersonalData.company,
    country: PersonalData.country,
    client_type: PersonalData.businessType,
    step2: selectedProducts.map((product) => product.label).join(", "),
    step3,
    step4,
  };

  const sendRequest = () => {
    Mail.sendMail(data, { lang: locale }).then(({ status }) => {
      if (status === 200) {
        setThanksModalOpen(true);
        setSending(false);
      }
    });
  };

  const validate = (value) => {
    const emailValid = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(value);
    return !!emailValid;
  };

  useEffect(() => {
    const isStepValid = () => {
      switch (activeStep) {
        case 1:
          return (
            PersonalData.businessType &&
            PersonalData.company &&
            PersonalData.country &&
            PersonalData.email
          );
        case 2:
          return selectedProducts.length > 0;
        case 3:
          return selectedDataPoints.length > 0;
        case 4:
          return selectedProductUniverses.range && selectedProductUniverses.dataQuality.length > 0 && selectedProductUniverses.markets.length > 0;
        default:
          return true;
      }
    };

    setDisabledNext(!isStepValid());
  }, [PersonalData, selectedProducts, selectedProductUniverses, selectedDataPoints, activeStep]);

  const handleNext = () => {
    if (activeStep < 5) {
      validate(PersonalData.email) ? setActiveStep((prevStep) => prevStep + 1) : setError(true);
    } else {
      setSending(true);
      sendRequest();
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      navigate(urlLanding);
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPersonalData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  };

  const handleChangeProductUniverse = (updateProductUniverses) => {
    setSelectedProductUniverses((prevProductUniverses) => ({
      ...prevProductUniverses,
      ...updateProductUniverses,
    }));
  };

  const handleProductChange = (products) => {
    setSelectedProducts(products);
  };

  const handleDataPointChange = (dataPoints) => {
    setSelectedDataPoints(dataPoints);
  };
  const handleStepClick = (step) => {
    if (step < activeStep || (validate(PersonalData.email) && !disabledNext)) {
      setActiveStep(step);
      setError(false);
    } else {
      setError(true);
    }
  };

  const buttonSuccessAction = () => {
    navigate(urlLanding);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Seo title={metadata.title} description={metadata.description} noIndex />
        <Header />
        <Container>
          {activeStep !== 5 && <StepProgress activeStep={activeStep} onStepClick={handleStepClick} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {activeStep === 1 && (
              <Box mt={{ xs: 4, sm: 12 }} mb={6}>
                <FormStep1 formData={PersonalData} handleChange={handleChange} error={error} />
              </Box>
            )}
              {activeStep === 2 && (
              <FormStep2 selectedProducts={selectedProducts} handleProductChange={handleProductChange} />
            )}
              {activeStep === 3 && (
              <>
                <Stack my={4} spacing={1}>
                  <Typography variant="h4">
                    <Trans>
                      Build your data feed
                    </Trans>
                  </Typography>
                  <Stack>
                    <Typography>
                      <Trans>
                        Explore our catalog and define the data points of your interest.
                      </Trans>
                    </Typography>
                    <Typography>
                      <Trans>
                        Gain detailed insights for the different products.
                      </Trans>
                    </Typography>
                  </Stack>
                </Stack>
                <FormStep3
                  selectedDataPoints={selectedDataPoints}
                  selectedProducts={selectedProducts}
                  handleDataPoint
                  handleDataPointChange={handleDataPointChange}
                />
              </>
            )}
              {activeStep === 4 && (
              <>
                <Stack my={4} spacing={0.5}>
                  <Typography variant="h2">
                    <Trans>
                      Define your product universe and other relevant input
                    </Trans>
                  </Typography>
                  <Typography>
                    <Trans>
                      You are very close to completing the process. In this final step, please refine your request for a more precise solution.
                    </Trans>
                  </Typography>
                </Stack>
                <FormStep4 formData={selectedProductUniverses} handleChange={handleChangeProductUniverse} />
              </>
            )}
              {activeStep === 5 && (
              <ReviewStep
                personalData={PersonalData}
                selectedProducts={selectedProducts}
                selectedDataPoints={selectedDataPoints}
                handleBack={handleBack}
                selectedProductUniverses={selectedProductUniverses}
              />
            )}
            </Grid>
          </Grid>
          <Box mt={{ xs: 0, lg: 8}} mb={4}>
            <Grid container justifyContent={{xs: "space-between", lg: activeStep === 5 ? "center" : "space-between"}} gap={{xs: 0, lg: activeStep === 5 ? 2 : 0}}>
              <Box width={{ xs: "48%", lg: "193px" }}>
                <Button
                  variant="secondary-outline"
                  fullWidth
                  onClick={handleBack}
            >
                  Back
                </Button>
              </Box>
              <Box width={{ xs: "48%", lg: "193px" }}>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => handleNext()}
                  sx={{
                ...(disabledButton && {
                  opacity: 0.5,
                  color: "white !important",
                }),
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: theme.palette.colors.red.dark,
                },
                }}
                  disabled={disabledButton}
            >
                  {activeStep === 5 ? "Send Request" : "Next"}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
      {thanksModalOpen && (
      <ThankRequestModal
        onClose={() => setThanksModalOpen(false)}
        buttonSuccessAction={buttonSuccessAction}
          />
      )}

    </>
  );
}

export default ResquestInfo;
